.App {
	padding: 10px;
}

#header.smaller {
	position: sticky;
	top: 0vh;
	z-index: 1;
	width: 100%;
	height: 120px !important;
	margin-bottom: 400px;
}

#header.smaller #logo {
	width: 110px;
	height: 105px;
	position: fixed;
	left: 12px;
	top: 10px;
}

#header.smaller #subtitle {
	font-size: 20px;
	position: fixed;
	left: 130px;
	top: 62px;
}

#header.smaller #spacer {
	padding-left: 20px;
}

#spacer {
	padding: 50px;
}

#spacerH {
	padding: 2vmin;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

header {
	height: 300px;
	padding-bottom: 10px;
	transition: ease-in-out;
}

#project {
	transition: 0.5s;
	opacity: 0.7;
	max-width: 95vw;
}

#project:hover {
	transform: scale(1.02);
	opacity: 1;
}

#card1 {
	animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
	max-width: 95vw;
}

.cardImage {
	position: relative;
	top: 0;
	right: 0px;
	max-width: 100%;
}

#card2 {
	animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
	max-width: 95vw;
}

#card3 {
	animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
	max-width: 95vw;
}

#card4 {
	animation: fadeInAnimation ease 6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
	max-width: 95vw;
}

@keyframes fadeInAnimation {
	 0% {
			 opacity: 0;
	 }
	 100% {
			 opacity: 1;
	 }
}

.p-tooltip {
	max-width: 500px;
}
